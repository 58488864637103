import React from "react"
import HomePage from "./pages/homePage/index"


const App = () => {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
